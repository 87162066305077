import { Injectable } from '@angular/core';
import { IndividualConfig, ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  constructor(private toastr: ToastrService) {}
  toastrOptions: Partial<IndividualConfig<any>> = {
    timeOut: 10000,
    closeButton: true,
    progressBar: true,
    enableHtml: true,
  };
  showSuccess(message: string, title: string = 'Success') {
    this.toastr.success('</br>' + message, title, this.toastrOptions);
  }

  showError(message: string, title: string = 'Error') {
    this.toastr.error('</br>' + message, title, this.toastrOptions);
  }

  showInfo(message: string, title: string = 'Info') {
    this.toastr.info('</br>' + message, title, this.toastrOptions);
  }

  showWarning(message: string, title: string = 'Warning') {
    this.toastr.warning('</br>' + message, title, this.toastrOptions);
  }
}
